import React, { useState, useEffect } from "react";
import { TextGroup, TextLabel, TextField, Button } from "../../Styled";
import { Form, Row, Col } from "reactstrap";
import classnames from "classnames";
import "./checklistStyle.css";
import { CloseIcon } from "../../../constants/icons";

const AddBomChecklistForm = (props) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(props.errors);
  }, [props]);

  return (
    <div className="modalContent">
      <div className="modalHeader">
        <h4 className="modalTitle">Add New Checklist</h4>
        <p>All fields are required</p>
        <span className="closeIcon" onClick={() => props.close(false)}>
          <CloseIcon />
        </span>
      </div>
      <Form className="w-100 white-form" onSubmit={props.submit}>
        {/* <Label className="fileUpload">
          <div className="avtarFile">
            <img src="./images/avtar.svg" alt="avtar" />
          </div>
          <Input type="file" name="avtar" accept="image/*" />
          <div className="avtarText">
            <h5>Add Profile Picture</h5>
            <p>Image dimension (Minimum 200x200px, Less than 1 MB)</p>
          </div>
        </Label> */}
        <Row>
          <Col>
            <TextGroup className={classnames({ error: errors.name })}>
              <TextLabel>Name {Object.keys(props?.requiredField)?.includes('name') ? ' *' : ''}</TextLabel>
              <TextField
                type="text"
                name="name"
                placeholder="Name"
                value={props.fillData.name || ""}
                onChange={props.change}
              />
              {errors.name ? (
                <span className="error-text">{errors.name}</span>
              ) : null}
            </TextGroup>
          </Col>
        </Row>

        <TextGroup>
          <Button className="importBtn blue px-5 w-auto" type="submit">
            Save
          </Button>
        </TextGroup>
      </Form>
    </div>
  );
};

export default AddBomChecklistForm;

import {
  SET_USER,
  UNSET_USER,
  UPDATE_PROFILE,
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  SET_COLUMN,
  UNSET_COLUMN,
  SET_COLUMN_ALIASES,
  UNSET_COLUMN_ALIASES,
  IMPORT_FILTER_DATA,
  UPDATE_QUOTES,
  UNSET_QUOTE,
  GET_CUSTOMERS,
  ADD_CUSTOMERS,
  GET_PARTS_REVS,
  ADD_PARTS_REVS,
  UPDATE_TOKEN,
} from "../constants/actionTypes";
import {
  SIGNUP,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  GET_USERS,
  GET_BOMCHECKLISTS,
  ADD_BOMCHECKLIST,
  UPDATE_BOMCHECKLIST,
  DELETE_BOMCHECKLIST,
  UPDATE_USER,
  DELETE_USER,
  COLUMNS_LIST,
  COLUMN_ALIASES,
  ADD_USER,
  GET_ROLES,
  CUSTOMERS_LIST,
  CUSTOMERS,
  DISTRIBUTORS_LIST,
  PARTS_REVS_LIST,
  SAVE_PARTS_REVS,
  CHANGE_PASSWORD,
  UPDATE_VENDOR_LIST,
  BOM_HISTORY,
  GET_FILE,
  SHARE_BOM,
  STORE_RESULTANT_BOM,
} from "../constants/urls";
import { post, get, put, delet } from "../helpers";
import { toaster } from "../function";
import { ToastsStore } from "react-toasts";

export const signup = ({ email, password, firstName, lastName, mobile }) => (
  dispatch
) => {
  dispatch({ type: SIGNUP_REQUEST });
  post({
    url: SIGNUP,
    body: { email, password, firstName, lastName, mobile },
    success: SET_USER,
    failure: UNSET_USER,
    dispatch,
  });
};

export const login = ({ email, password }) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  const data = await post({
    url: LOGIN,
    body: { email, password },
    failure: UNSET_USER,
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    if (data.data.is_allowed_ip === false) {
      dispatch({ type: UNSET_USER });
      return data;
    }
    if (data.data.user.is_active) {
      dispatch({ type: SET_USER, data: data.data });
    }
    return data;
  } else {
    dispatch({ type: UNSET_USER });
    return data;
  }
};

export const logout = (token) => async (dispatch) => {
  const data = await post({
    url: LOGOUT,
    body: {},
    headers: {
      authorization: "Bearer " + token,
    },
    success: UNSET_USER,
    failure: UNSET_USER,
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: UNSET_USER });
    dispatch({ type: UNSET_COLUMN });
    dispatch({ type: UNSET_COLUMN_ALIASES });
    dispatch({ type: UNSET_QUOTE });
    return data;
  } else {
    dispatch({ type: UNSET_USER });
    return data;
  }
};

export const forgotPassword = ({ email }) => async (dispatch) => {
  dispatch({ type: UNSET_USER });
  const data = await post({
    url: FORGOT_PASSWORD,
    body: { email },
    failure: "",
    dispatch,
  });

  return data;
};

export const getColumns = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? COLUMNS_LIST + "?debug=1"
        : COLUMNS_LIST,
    success: SET_COLUMN,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: SET_COLUMN, data });
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    dispatch({ type: "" });
    return data;
  }
};

export const getColumnAliases = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? COLUMN_ALIASES + "?debug=1"
        : COLUMN_ALIASES,
    success: SET_COLUMN_ALIASES,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: SET_COLUMN_ALIASES, data });
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    dispatch({ type: "" });
    return data;
  }
};

export const getCustomers = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? CUSTOMERS_LIST + "?debug=1"
        : CUSTOMERS_LIST,
    success: GET_CUSTOMERS,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: GET_CUSTOMERS, data });
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    dispatch({ type: "" });
    return data;
  }
};

export const saveCustomer = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? CUSTOMERS + "?debug=1"
        : CUSTOMERS,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });
  if (res.code >= 200 && res.code < 400) {
    dispatch({ type: ADD_CUSTOMERS, data: res.data });
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", "There were some errors.");
    return res;
  }
};

export const changePassword = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? CHANGE_PASSWORD + "?debug=1"
        : CHANGE_PASSWORD,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    dispatch({ type: UPDATE_TOKEN, data: res.data });
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const postImportRecords = (token, data) => (dispatch) => {
  dispatch({ type: IMPORT_FILTER_DATA, data });
};

export const getUser = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? GET_USERS + "?debug=1"
        : GET_USERS,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const addUser = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? ADD_USER + "?debug=1"
        : ADD_USER,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    return res;
  } else {
    if (res.message)
      // toaster(ToastsStore, 'error', res.message)
      return res;
  }
};

export const editUser = (token, data) => async (dispatch) => {
  const res = await put({
    url:
      localStorage.getItem("isDebug") === "1"
        ? UPDATE_USER + `${data.id}?debug=1`
        : UPDATE_USER + `${data.id}`,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const profileUpdate = (token, data) => async (dispatch) => {
  const res = await put({
    url:
      localStorage.getItem("isDebug") === "1"
        ? UPDATE_USER + `${data.id}?debug=1`
        : UPDATE_USER + `${data.id}`,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    dispatch({ type: UPDATE_PROFILE, data: res.data });
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const deleteUser = (token) => (dispatch) => {
  delet({
    url: DELETE_USER,
    success: UNSET_USER,
    body: {},
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });
};

export const getBomChecklist = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? GET_BOMCHECKLISTS + "?debug=1"
        : GET_BOMCHECKLISTS,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const addBomChecklist = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? ADD_BOMCHECKLIST + "?debug=1"
        : ADD_BOMCHECKLIST,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    return res;
  } else {
    if (res.message)
      // toaster(ToastsStore, 'error', res.message)
      return res;
  }
};

export const editBomChecklist = (token, data) => async (dispatch) => {
  const res = await put({
    url:
      localStorage.getItem("isDebug") === "1"
        ? UPDATE_BOMCHECKLIST + `/${data.id}?debug=1`
        : UPDATE_BOMCHECKLIST + `/${data.id}`,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const deleteBomChecklist = (token, data) => async (dispatch) => {
  const res = await delet({
    url:
      localStorage.getItem("isDebug") === "1"
        ? DELETE_BOMCHECKLIST + `${data.id}?debug=1`
        : DELETE_BOMCHECKLIST + `/${data.id}`,
    // body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const updateQuotes = (data) => (dispatch) => {
  dispatch({ type: UPDATE_QUOTES, data });
};

export const getRoles = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? GET_ROLES + "?debug=1"
        : GET_ROLES,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const getDistributor = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? DISTRIBUTORS_LIST + "?debug=1"
        : DISTRIBUTORS_LIST,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 401 || data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const getBomHistory = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? BOM_HISTORY + "?debug=1"
        : BOM_HISTORY,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 401 || data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const getBomDetails = (token, id) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        // eslint-disable-next-line no-useless-concat
        ? BOM_HISTORY + "/" + `${id}` + "?debug=1"
        // eslint-disable-next-line no-useless-concat
        : BOM_HISTORY + "/" + `${id}`,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code === 401 || data.code === 403) {
    return dispatch({ type: UNSET_USER });
  }

  if (data.code >= 200 && data.code < 400) {
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    return data;
  }
};

export const getFile = (token, filePath) => async (dispatch) => {
  const data = await fetch(GET_FILE, {
    method: "GET",
    headers: {
      'authorization': "Bearer " + token,
      'file-name': filePath,
    },
    failure: "",
    dispatch,
  });

  if (data.status >= 200 && data.status < 400) {
    var fileBlob = await data.blob();
    return fileBlob;
  } else if (data.status === 404) {
    toaster(ToastsStore, "error", "Could not find the file.");
  } else {
    toaster(ToastsStore, "error", "Could not get the file.");
  }

  return null;
};

export const shareFile = (token, filePath, bomName) => async (dispatch) => {
  const data = await fetch(SHARE_BOM, {
    method: "GET",
    headers: {
      'authorization': "Bearer " + token,
      'file-name': filePath,
      'bom-name': bomName,
    },
    failure: "",
    dispatch,
  });
  return data;
};

//--- Parts REVS
export const getPartsRevs = (token) => async (dispatch) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? PARTS_REVS_LIST + "?debug=1"
        : PARTS_REVS_LIST,
    success: GET_PARTS_REVS,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: GET_PARTS_REVS, data });
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    dispatch({ type: "" });
    return data;
  }
};

export const getPartsRevsByCustomerId = (token, customerId) => async (
  dispatch
) => {
  const data = await get({
    url:
      localStorage.getItem("isDebug") === "1"
        ? PARTS_REVS_LIST + "/" + customerId + "?debug=1"
        : PARTS_REVS_LIST + "/" + customerId,
    success: GET_PARTS_REVS,
    headers: {
      authorization: "Bearer " + token,
    },
    failure: "",
    dispatch,
  });

  if (data.code >= 200 && data.code < 400) {
    dispatch({ type: GET_PARTS_REVS, data });
    return data;
  } else {
    if (data.message) toaster(ToastsStore, "error", data.message);
    dispatch({ type: "" });
    return data;
  }
};

export const savePartsRevs = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? SAVE_PARTS_REVS + "?debug=1"
        : SAVE_PARTS_REVS,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    dispatch({ type: ADD_PARTS_REVS, data: res.data });
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", "There were some errors.");
    return res;
  }
};

export const updateVendor = (token, data) => async (dispatch) => {
  const res = await post({
    url:
      localStorage.getItem("isDebug") === "1"
        ? UPDATE_VENDOR_LIST + "?debug=1"
        : UPDATE_VENDOR_LIST,
    body: data,
    headers: {
      authorization: "Bearer " + token,
    },
    success: "",
    failure: "",
    dispatch,
  });

  if (res.code >= 200 && res.code < 400) {
    // dispatch({ type: UPDATE_VENDOR, data:res.data })
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

export const storeResultantBom = async (token, data) => {
  const formData = new FormData();
  formData.append("bom_id", data.bomId);
  formData.append("file_name", data.fileName);
  formData.append("file", data.file);

  const res = await fetch(STORE_RESULTANT_BOM, {
    method: "POST",
    headers: {
      authorization: "Bearer " + token,
    },
    body: formData,
  });

  if (data.code >= 200 && data.code < 400) {
    return res;
  } else {
    if (res.message) toaster(ToastsStore, "error", res.message);
    return res;
  }
};

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Layouts from "../../Layouts";
import Datatable from "../../Datatable";
import { Input, Button } from "reactstrap";
import Paginations from "../../Datatable/Paginations";
import SideModal from "../../Modals/SideModal";
import AddBomChecklistForm from "./AddBomChecklistForm";
import { getRoles, getBomChecklist, addBomChecklist, editBomChecklist, deleteBomChecklist } from "../../../actions";
import { connect } from "react-redux";
import { formValidation } from "../../../function";
import Moment from "react-moment";
import EditBomChecklistForm from "./EditBomChecklistForm";
import FormConfirmation from "../../Modals/FormConfirmation";
import DeleteConfirmation from "../../Modals/DeleteConfirmation";

const requiredField = {
  name: ["required"],
};

const BomChecklist = (props) => {
  const [searchText, setSearchText] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [roles, setRoles] = useState([]);
  const [bomChecklist, setBomChecklist] = useState([]);
  const [addFormData, setAddFormData] = useState({allowed_ip: "*"});
  const [editFormData, setEditFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [formConfirm, setFormConfirm] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteFormData, setDeleteFormData] = useState({});
  const { user } = props;

  useEffect(() => {
    const getBomChecklists = async () => {
      const res = await props.getBomChecklist(props.user.token);
      setBomChecklist(res.data);
    };

    const getRole = async () => {
      const resRole = await props.getRoles(props.user.token);
      setRoles(resRole.data);
    };
    getRole();
    getBomChecklists();
  }, [props]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPageActive(1);
  };

  const handlePopup = (e) => {
    if (formChanged) {
      setFormConfirm(true);
      return false;
    }
    setOpenAddForm(e);
    setErrors({});
    setAddFormData({});
  };

  const formConfirmPopup = (confirm) => {
    if (confirm) {
      setFormChanged(false);
      setOpenAddForm(false);
      setOpenEditForm(false);
      setErrors({});
      setAddFormData({});
    }
    return setFormConfirm(false);
  };

  const deleteConfirmPopup = async (confirmDelete) => {
    if (confirmDelete) {
      const deleteData = {
        id: deleteFormData.id,
        name: deleteFormData.name,
        active: deleteFormData.active,
      };
      const deleteChecklist = await props.deleteBomChecklist(props.user.token, deleteData);

      if (deleteChecklist.code >= 200 && deleteChecklist.code < 400) {
        const res = await props.getBomChecklist(props.user.token);
        setBomChecklist(res.data);
      } else {
        setErrors(deleteChecklist.message);
      }
    }
    setDeleteFormData({});
    return setDeleteConfirm(false);
  };

  const handleEditPopup = () => {
    if (formChanged) {
      setFormConfirm(true);
      return false;
    }
    setOpenEditForm(false);
    setErrors({});
  };

  const handleFormChange = (e) => {
    setFormChanged(true);
    setAddFormData({ ...addFormData, [e.target.name]: e.target.value });
  };

  const handleEditFormChange = (e) => {
    setFormChanged(true);
    return setEditFormData({
      ...editFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      ...addFormData,
      active: 1,
      created_by: props.user.user.id
    };

    let validate = formValidation(requiredField, postData);

    if (validate.formIsValid) {
      const res = await props.addBomChecklist(props.user.token, postData);
      let bomChecklists = bomChecklist.concat(res.data);
      setBomChecklist(bomChecklists);
      setOpenAddForm(false);
      setAddFormData({allowed_ip: "*"});
      setErrors({});
      setFormChanged(false);
    } else {
      setErrors(validate.errors);
    }
  };

  const checklistUpdate = async (e) => {
    if (e) {
      e.preventDefault();
    }
    // if (editFormData.password && editFormData.confirm_password && editFormData.password !== editFormData.confirm_password) {
    //   return setErrors({
    //     password: "Password and Confirm Password must be same.",
    //     confirm_password: "Password and Confirm Password must be same.",
    //   });
    // }
    const postUpdateData = {
      id: editFormData.id,
      name: editFormData.name,
      active: editFormData.active,
      updated_by: props.user.user.id,
    };

    let validateField = { ...requiredField };

    let validate = formValidation(validateField, postUpdateData);
    if (validate.formIsValid) {
      apiCall(postUpdateData);
    } else {
      setErrors(validate.errors);
    }
  };

  const apiCall = async (apiData) => {
    const updatedChecklist = await props.editBomChecklist(props.user.token, apiData);

    if (updatedChecklist.code >= 200 && updatedChecklist.code < 400) {
      let checklists = bomChecklist.map((item) => {
        if (updatedChecklist.data.id === item.id) {
          return (item = updatedChecklist.data);
        }
        return item;
      });
      setBomChecklist(checklists);
      setOpenEditForm(false);
      setErrors({});
      setEditFormData({});
      setFormChanged(false);
    } else {
      setErrors(updatedChecklist.message);
    }
  };

  const showChecklist = (checklist) => {
    setOpenEditForm(true);
    setEditFormData(checklist);
  };

  const deleteChecklist = (checklist) => {
    setDeleteConfirm(true);
    setDeleteFormData(checklist);
  };

  const closeHandlePopup = (e) => {
    console.log('');
  };

  const column = [
    {
      fieldName: "id",
      label: "ID",
      sort: true,
      customRow: (row) => (
        <div className={classnames({ "opacity-50": !row.active })}>
          {/* <img src="./images/user.svg" alt="avtar" /> */}
          <span>{row.id}</span>
        </div>
      ),
    },
    {
      fieldName: "name",
      label: "Name",
      sort: true,
      customRow: (row) => (
        <div className={classnames({ "opacity-50": !row.active })}>
          {/* <img src="./images/user.svg" alt="avtar" /> */}
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      fieldName: "active",
      label: "Status",
      sort: true,
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.active })}>
          {row.active ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      fieldName: "created_at",
      label: "Added On",
      sort: true,
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.active })}>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment>
        </span>
      ),
    },
    {
      fieldName: "",
      label: "Actions",
      width: 120,
      align: "center",
      customRow: (row) => (
        (user && user.user.role_id === 1) ?
          <>
            <Button size="sm" color="default" onClick={() => {
              showChecklist(row)
            }}>
              <span className="fa fa-pencil"></span>
            </Button>
            <Button size="sm" color="default" onClick={() => {
              deleteChecklist(row)
            }}>
              <span className="fa fa-trash"></span>
            </Button>
          </>
        : <></>
      ),
    },
  ];

  const [showEntry] = useState(10);
  const [pageActive, setPageActive] = useState(1);
  const [totalRecords, setTotalRecords] = useState(bomChecklist.length);

  const handlePagination = (e) => {
    setPageActive(e);
  };

  const changeTableParams = (total, page) => {
    if (page) setPageActive(page);
    setTotalRecords(total);
  };

  return (
    <Layouts>
      <SideModal show={openAddForm} close={handlePopup}>
        <AddBomChecklistForm
          roles={roles}
          errors={errors}
          change={handleFormChange}
          fillData={addFormData}
          submit={onSubmit}
          close={handlePopup}
          requiredField={requiredField}
        />
      </SideModal>

      <SideModal show={openEditForm} close={handleEditPopup}>
        <EditBomChecklistForm
          roles={roles}
          errors={errors}
          change={handleEditFormChange}
          fillData={editFormData}
          submit={checklistUpdate}
          close={handleEditPopup}
          requiredField={requiredField}
          user={user}
        />
      </SideModal>

      <FormConfirmation
        show={formConfirm}
        toggle={handlePopup}
        confirm={formConfirmPopup}
      />

      <DeleteConfirmation
        show={deleteConfirm}
        toggle={closeHandlePopup}
        confirm={deleteConfirmPopup}
      />

      <div className="p-20">
        <div className="card">
          <div className="cardHeader">
            <h3>
              Bom Checklists
              <small className="d-block">Total - {bomChecklist.length}</small>
            </h3>
            <div className="searchBox">
              <Input
                type="text"
                placeholder="Search..."
                name="searchText"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {user && user.user.role_id === 1 &&
              <Button className="linkBtn" onClick={() => handlePopup(true)}>
                <i className="fa fa-plus mr-2"></i>
                Add New Checklist
              </Button>
            }
          </div>
          <Datatable
            data={bomChecklist}
            columns={column}
            pageActive={pageActive}
            showEntry={showEntry}
            searchText={searchText}
            changeTableParams={changeTableParams}
          />
        </div>
        {totalRecords > showEntry && (
          <Paginations
            totalRecords={totalRecords}
            showEntry={showEntry}
            pageActive={pageActive}
            click={handlePagination}
            prevClick={() => setPageActive(parseInt(pageActive) - 1)}
            nextClick={() => setPageActive(parseInt(pageActive) + 1)}
          />
        )}

      </div>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {
  getRoles,
  getBomChecklist,
  addBomChecklist,
  editBomChecklist,
  deleteBomChecklist,
})(BomChecklist);

import styled from "styled-components";

export const ModalOverlay = styled.div`
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(35, 35, 35, 0.67);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1051;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;
export const ModalBox = styled.div`
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
  &.smBox {
    max-width: 390px;
  }
  &.smdBox {
    min-width: 330px;
    max-width: 390px;
  }
  .modal-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: var(--dark-blue-grey);
  }
  .sub-title {
    font-size: 13px;
    margin: 0 0 15px;
    color: #636e95;
  }
  .modal-text {
    font-size: 15px;
    margin: 15px 0;
    color: #636e95;
    &.timer-text {
      display: flex;
      align-items: center;
      small {
        color: #636e95;
        font-size: 13px;
        line-height: 13px;
      }
      svg {
        max-width: 40px;
        margin-right: 15px;
      }
      .counter {
        color: var(--dark-blue-grey);
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
`;
export const RightModal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 45%;
  max-width: 430px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 2px 19px 0 rgba(152, 152, 152, 0.37);
  background-color: var(--white);
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s ease;
  z-index: 1051;
  &.open {
    transform: translate3d(0, 0, 0);
  }
  .block-title {
    font-size: 18px;
    font-weight: 500;
    color: var(--dark-blue-grey);
    margin-bottom: 30px;
    width: 100%;
  }
  // .importBtn:hover {
  //     background-color: #bfc7e0;
  // }
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`;

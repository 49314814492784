import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { storeResultantBom } from "../../../actions";

const addATemplateRowWithBasicCellFormat = (sheet, titleData) => {
  let columnsCount = Object.keys(titleData).length;
  let rowCellsStrings = Array(columnsCount).join(".").split(".");
  let rowTemplate = sheet.addRow(rowCellsStrings);
  rowTemplate._cells.forEach((cell, index) => {
    cell.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
  });
  return rowTemplate;
};

const addSheetTitleRowAndGetInputColumnsCount = (
  rowTemplate,
  titleData,
  columnNames
) => {
  let inputColumnsCount = 0;
  let titleRow = Object.keys(titleData);
  rowTemplate._cells.forEach((cell, index) => {
    let argb = "6c757d";
    let cellValue = columnNames.find(
      (item) => item.code_name === titleRow[index]
    );

    if (!cellValue) {
      cellValue = { name: titleRow[index] };
      argb = titleRow[index].indexOf("Quote ") !== -1 ? "0080ff" : "04b78a";
    } else {
      inputColumnsCount++;
    }

    cell.value = cellValue.name;
    cell.font = {
      color: { argb: "FFFFFFFF" },
      bold: true,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: argb },
    };
  });

  return inputColumnsCount;
};

const isQuantityCellWithInvalidValue = (
  cellColumnTitle,
  invalidQuantityColumns,
  index
) => {
  return (
    cellColumnTitle === "quantity" && invalidQuantityColumns.includes(index + 1)
  );
};

const getRichDescriptionCellValue = (descriptionObject) => {
  let descriptionCellRichTextArray = [];

  // eslint-disable-next-line array-callback-return
  descriptionObject.description.map(function (t) {
    if (t.key) {
      descriptionCellRichTextArray.push({
        font: {
          color: { argb: "636e95" },
        },
        text: t.key + ": ",
      });

      let value = t.link ? t.link : t.value ? t.value : "n/a";
      descriptionCellRichTextArray.push({
        text: value + " \r\n",
      });
    }
  });
  return {
    richText: descriptionCellRichTextArray,
  };
};

const getRichResultCellValue = (resultObject) => {
  let resultCellRichTextArray = [];

  if (resultObject.title) {
    resultCellRichTextArray.push({
      font: {
        color: { argb: "4b499f" },
      },
      text: resultObject.title + " \r\n",
    });
  }


  if(resultObject?.text?.length > 0){
    // eslint-disable-next-line array-callback-return
    resultObject.text.map(function (t) {
      if (t.text) {
        resultCellRichTextArray.push({
          font: {
            italic: t.class === "tdItalicSubTitle",
          },
          text: t.text,
        });
      }
    });
  }
  return {
    richText: resultCellRichTextArray,
  };
};

const getColorForResultCell = (resultObject) => {
  switch (resultObject.class) {
    case "bg-green":
      return "effbf8";
    case "bg-yellow":
      return "ffedad";
    case "bg-light-maroon":
      return "fdeeee";
    case "bg-gray-opacity":
      return "e9e9fc";
    default:
      return "ffffff";
  }
};

const addSheetDataRow = (
  rowTemplate,
  titleData,
  item,
  invalidQuantityColumns,
  rowIndex,
  sheet,
  lastBomLineIndex,
  bomChecklists
) => {
  let titleRow = Object.keys(titleData);
  rowTemplate._cells.forEach((cell, index) => {
    let cellValue = item[titleRow[index]];
    let argb = isQuantityCellWithInvalidValue(
      titleRow[index],
      invalidQuantityColumns,
      rowIndex
    )
      ? "fdeeee"
      : "ffffff";

    if (
      cellValue != null &&
      typeof cellValue === "object" &&
      cellValue.description != null
    ) {
      cellValue = getRichDescriptionCellValue(cellValue);
    } else if (cellValue != null && typeof cellValue === "object") {
      argb = getColorForResultCell(cellValue);
      cellValue = getRichResultCellValue(cellValue);
    }
    const regexTest = /^BOM Manager Result \d+$/;
    if (cellValue && regexTest.test(titleRow[index]) && (cellValue?.richText?.length === 1)) {
      if (cellValue?.richText[0].text === 'Multiple Manufacturers') {
        cellValue = {
          richText: [
            {
              text: cellValue?.richText[0].text,
              font: {
                color: {
                  argb: 'ff0000',
                  theme: 1,
                },
                bold: true,
              },
            },
          ],
        };
      }
    }
    if (cellValue && (titleRow[index] === 'designator' || titleRow[index] === 'manufacturer_part_number') && item['designator_color']) {
      cellValue = {
        richText: [
          {
            text: cellValue,
            font: {
              color: {
                argb: 'a02b93',
                theme: 1,
              },
              bold: true,
            },
          },
          // {
          //   text: item['added_designator'],
          //   font: {
          //     color: {
          //       argb: 'FF0000',
          //       theme: 1,
          //     },
          //   },
          // },
        ],
      };
    }
    cell.value = cellValue;
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: argb,
      },
    };
    cell.alignment = { wrapText: false };

    const regex = /^Quote \d+$/;
    if (regex.test(titleRow[index])) {   // for column Quote 1, Quote 2, Quote 3, Quote 4, Quote 5 and Quote 6
      if (rowIndex === lastBomLineIndex) {
        const letter = cell?._address.match(/[A-Z]+/g)?.join('') || ''; // This will give ("L" from "L5") or ("AA" from "AA6")
        var fromToCell = 'SUM(' + letter + '2:' +  letter + (lastBomLineIndex + 1) + ')';
        const quoteTotalCell = sheet.getCell(cell?._address);

        quoteTotalCell.value = { formula: fromToCell };
        quoteTotalCell.numFmt = '$###0.00';
      } else {
        let quoteAmount = parseFloat(cellValue?.richText[0].text.replace(/[$,]/g, ''));
        const quoteCell = sheet.getCell(cell?._address);
        quoteCell.numFmt = '$###0.00';
        quoteCell.value = quoteAmount;
      }
    }

    if (titleRow[index] === 'Quote 1' && rowIndex === lastBomLineIndex) {
      let listIndexNumber = 0;
      bomChecklists.forEach((checklist, listIndex) => {
        if (checklist?.active === 1) {
          let number = lastBomLineIndex + 4 + listIndexNumber;
          const cell2 = sheet.getCell('B' + number);
          cell2.value = checklist?.name;

          const cell1 = sheet.getCell('A' + number);
          cell1.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: ['"SELECT,TRUE,FALSE"'],
            showErrorMessage: true,
            errorTitle: 'Invalid selection',
            error: 'Please select a value from the dropdown list.',
            // showInputMessage: true, // Show input message when the cell is selected
            // promptTitle: 'Select an option',
            // prompt: 'Please choose "TRUE" or "FALSE".', // Input message
          };
          cell1.value = 'SELECT';

          listIndexNumber++;
        }
      });
    }

  });
};

const ExportExcel = async (
  user,
  data,
  invalidQuantityColumns,
  columns,
  exportLocally,
  bomChecklists
) => {
  const bomlines = data.bomlines;
  const bomId = data.bomId;

  let firstBomLine = Object.assign({}, bomlines[0]);
  delete firstBomLine.designator_color;

  const workbook = new ExcelJS.Workbook();

  var sheet = workbook.addWorksheet("result");
  let rowTemplate = addATemplateRowWithBasicCellFormat(sheet, firstBomLine);
  let inputColumnsCount = addSheetTitleRowAndGetInputColumnsCount(
    rowTemplate,
    firstBomLine,
    columns
  );

  var lastBomLineIndex = bomlines?.length - 1;

  bomlines.forEach((item, rowIndex) => {
    let rowTemplate = addATemplateRowWithBasicCellFormat(sheet, firstBomLine);
    addSheetDataRow(
      rowTemplate,
      firstBomLine,
      item,
      invalidQuantityColumns,
      rowIndex,
      sheet,
      lastBomLineIndex,
      bomChecklists
    );
  });

  // Try the solution from the following link if required: https://github.com/exceljs/exceljs/issues/83
  // Currently, allocating more width to the result details column.
  for (let i = inputColumnsCount + 4; i <= sheet.columnCount; i++) {
    if ((i - inputColumnsCount - 1) % 3 === 2) {
      sheet.getColumn(i).width = 11;
    } else {
      sheet.getColumn(i).width = 12;
    }
  }
  sheet.getColumn(1).width = 7;
  sheet.getColumn(inputColumnsCount + 1).width = 11;
  sheet.getColumn(inputColumnsCount + 2).width = 12;
  sheet.properties.defaultColWidth = 20;

  let fileNameFromLocalStorage = localStorage.getItem("fileName");
  let fileName =
    "Result-" + (fileNameFromLocalStorage ? fileNameFromLocalStorage : "BOM");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  await workbook.xlsx.writeBuffer().then(async (data) => {
    const blob = new Blob([data], { type: fileType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;

    // call backend to store the bom
    await storeResultantBom(user.token, {
      bomId: bomId,
      fileName: fileName,
      file: blob,
    });

    if (exportLocally) {
      FileSaver.saveAs(blob, fileName);
    }
  });
};

export default ExportExcel;

import React from "react";
import classnames from "classnames";
import { Row, Col, Button } from "reactstrap";
import { ModalOverlay, ModalBox } from "./ModalStyled";

const DeleteConfirmation = (props) => {
  return (
    <ModalOverlay className={classnames({ show: props.show })}>
      <ModalBox className="smdBox">
        <h2 className="modal-title">Delete Confirmation</h2>

        <p className="modal-text">Are you sure you want to delete this?</p>
        <Row>
          <Col>
            <Button
              className="btn-gray"
              block
              onClick={() => props.confirm(false)}
            >
              No,Please!
            </Button>
          </Col>
          <Col>
            <Button
              className="btn-blue"
              block
              onClick={() => props.confirm(true)}
            >
              Yes, Delete it!
            </Button>
          </Col>
        </Row>
      </ModalBox>
    </ModalOverlay>
  );
};

export default DeleteConfirmation;

import React from "react";
import { ReactComponent as Logo } from "../svg/logo-small.svg";

import { ReactComponent as Home } from "../svg/home-outline.svg";
import { ReactComponent as User } from "../svg/people-outline.svg";
import { ReactComponent as History } from "../svg/Layers.svg";
import { ReactComponent as Briefcase } from "../svg/briefcase-normal.svg";
import { ReactComponent as Pie } from "../svg/pie-chart-outline.svg";

import { ReactComponent as Import } from "../svg/import.svg";
import { ReactComponent as Export } from "../svg/exportIcon.svg";
import { ReactComponent as Search } from "../svg/search.svg";
import { ReactComponent as Clock } from "../svg/clock.svg";
import { ReactComponent as Close } from "../svg/close.svg";

import { ReactComponent as BackButton } from "../svg/back-button.svg";
import { ReactComponent as EmailImage } from "../svg/email-icon.svg";
import { ReactComponent as EyeOpen } from "../svg/eye-open.svg";
import { ReactComponent as EyeClose } from "../svg/eye-close.svg";
import { ReactComponent as ListCheck } from "../svg/list-check.svg";

export const LogoIcon = (props) => {
  return <Logo {...props} />;
};

export const HomeIcon = (props) => {
  return <Home {...props} />;
};
export const UserIcon = (props) => {
  return <User {...props} />;
};
export const HistoryIcon = (props) => {
  return <History {...props} />;
};
export const BriefcaseIcon = (props) => {
  return <Briefcase {...props} />;
};
export const PieIcon = (props) => {
  return <Pie {...props} />;
};

export const SearchIcon = (props) => {
  return <Search {...props} />;
};
export const ClockIcon = (props) => {
  return <Clock {...props} />;
};
export const CloseIcon = (props) => {
  return <Close {...props} />;
};
export const ImportIcon = (props) => {
  return <Import {...props} />;
};
export const ExportIcon = (props) => {
  return <Export {...props} />;
};

export const BackButtonIcon = (props) => {
  return <BackButton {...props} />;
};
export const EmailIcon = (props) => {
  return <EmailImage {...props} />;
};
export const EyeOpenIcon = (props) => {
  return <EyeOpen {...props} />;
};
export const EyeCloseIcon = (props) => {
  return <EyeClose {...props} />;
};
export const ListCheckIcon = (props) => {
  return <ListCheck {...props} />;
};
